<template>
  <div class="widget-custom-in news-component">
    <Swiper
      @swiper="onSwiper"
      :slidesPerView="1"
      :spaceBetween="25"
      :allowTouchMove="false"
      :onlyExternal="true"
      navigation
      :modules="modules"
    >
      <SwiperSlide class="news-component__item" v-for="news in newses" :key="news.id">
        <img v-if="news.previewPicture" class="news-component__img" :src="news.previewPicture" alt="Фото новости" />
        <h3 class="news-component__title">
          {{ news.name }}
        </h3>
        <p class="news-component__date">
          {{ $momentFormat(news.activeFrom, 'DD MMMM YYYY') }}
        </p>
        <p class="news-component__about">
          {{ news.previewText }}
        </p>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
  import { Navigation } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
  import 'swiper/swiper-bundle.css';

  import API from '../api/widgetElement';

  export default {
    name: 'Widget2',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadNews();
      },
    },
    data() {
      return {
        newses: [],
      };
    },
    created() {
      this.loadNews();
    },
    methods: {
      loadNews() {
        API.getNews({ countryId: this.countryId }).then((response) => {
          this.newses = response.data;
        });
      },
    },
    setup() {
      const onSwiper = (swiper) => {
        window.swiper = swiper;
      };
      return {
        modules: [Navigation],
        onSwiper,
      };
    },
  };
</script>

<style lang="scss">
  // News
  .news-component {
    &__img {
      width: 100%;
      height: 233px;
      object-fit: cover;
      margin-bottom: 20px;
    }
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 10px;
    }
    &__date {
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      color: #343c44;
      margin-bottom: 10px;
    }
    &__about {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      margin-bottom: 0px;
    }
  }
  .swiper {
    width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 25%;
    width: 10px;
    height: 10px;
    border-top: 40px solid transparent;
    border-left: 40px solid rgba(255, 255, 255, 0.5);
    border-right: 0px solid rgba(255, 255, 255, 0.5);
    border-bottom: 0px solid rgba(255, 255, 255, 0.5);
  }
  .swiper-button-prev {
    transform: rotate(45deg);
  }
  .swiper-button-next {
    transform: rotate(225deg);
  }

  @media (max-width: 425px) {
    .news-component__about {
      /* 3 точие */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      line-clamp: 7;
      box-orient: vertical;
    }
  }
</style>
